export const Block = () => import('../../components/ui/block.vue' /* webpackChunkName: "components/block" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../components/ui/checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const Counter = () => import('../../components/ui/counter.vue' /* webpackChunkName: "components/counter" */).then(c => wrapFunctional(c.default || c))
export const Cta = () => import('../../components/ui/cta.vue' /* webpackChunkName: "components/cta" */).then(c => wrapFunctional(c.default || c))
export const Dialog = () => import('../../components/ui/dialog.vue' /* webpackChunkName: "components/dialog" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../components/ui/dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const EmptyState = () => import('../../components/ui/emptyState.vue' /* webpackChunkName: "components/empty-state" */).then(c => wrapFunctional(c.default || c))
export const Favorite = () => import('../../components/ui/favorite.vue' /* webpackChunkName: "components/favorite" */).then(c => wrapFunctional(c.default || c))
export const Field = () => import('../../components/ui/field.vue' /* webpackChunkName: "components/field" */).then(c => wrapFunctional(c.default || c))
export const FieldText = () => import('../../components/ui/fieldText.vue' /* webpackChunkName: "components/field-text" */).then(c => wrapFunctional(c.default || c))
export const Img = () => import('../../components/ui/img.vue' /* webpackChunkName: "components/img" */).then(c => wrapFunctional(c.default || c))
export const Link = () => import('../../components/ui/link.vue' /* webpackChunkName: "components/link" */).then(c => wrapFunctional(c.default || c))
export const LoadMore = () => import('../../components/ui/loadMore.vue' /* webpackChunkName: "components/load-more" */).then(c => wrapFunctional(c.default || c))
export const NewsletterInput = () => import('../../components/ui/newsletterInput.vue' /* webpackChunkName: "components/newsletter-input" */).then(c => wrapFunctional(c.default || c))
export const OnOff = () => import('../../components/ui/onOff.vue' /* webpackChunkName: "components/on-off" */).then(c => wrapFunctional(c.default || c))
export const PrimaryButton = () => import('../../components/ui/primaryButton.vue' /* webpackChunkName: "components/primary-button" */).then(c => wrapFunctional(c.default || c))
export const Rating = () => import('../../components/ui/rating.vue' /* webpackChunkName: "components/rating" */).then(c => wrapFunctional(c.default || c))
export const SingleSelect = () => import('../../components/ui/singleSelect.vue' /* webpackChunkName: "components/single-select" */).then(c => wrapFunctional(c.default || c))
export const Skeleton = () => import('../../components/ui/skeleton.vue' /* webpackChunkName: "components/skeleton" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../components/ui/slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const SliderAlt = () => import('../../components/ui/sliderAlt.vue' /* webpackChunkName: "components/slider-alt" */).then(c => wrapFunctional(c.default || c))
export const Spinner = () => import('../../components/ui/spinner.vue' /* webpackChunkName: "components/spinner" */).then(c => wrapFunctional(c.default || c))
export const Star = () => import('../../components/ui/star.vue' /* webpackChunkName: "components/star" */).then(c => wrapFunctional(c.default || c))
export const StockNotification = () => import('../../components/ui/stockNotification.vue' /* webpackChunkName: "components/stock-notification" */).then(c => wrapFunctional(c.default || c))
export const Swiper = () => import('../../components/ui/swiper.vue' /* webpackChunkName: "components/swiper" */).then(c => wrapFunctional(c.default || c))
export const VideoEmbed = () => import('../../components/ui/videoEmbed.vue' /* webpackChunkName: "components/video-embed" */).then(c => wrapFunctional(c.default || c))
export const BlogCategories = () => import('../../components/blogCategories.vue' /* webpackChunkName: "components/blog-categories" */).then(c => wrapFunctional(c.default || c))
export const BlogPost = () => import('../../components/blogPost.vue' /* webpackChunkName: "components/blog-post" */).then(c => wrapFunctional(c.default || c))
export const ChatButton = () => import('../../components/chatButton.vue' /* webpackChunkName: "components/chat-button" */).then(c => wrapFunctional(c.default || c))
export const ChatForm = () => import('../../components/chatForm.vue' /* webpackChunkName: "components/chat-form" */).then(c => wrapFunctional(c.default || c))
export const ClinicMenu = () => import('../../components/clinicMenu.vue' /* webpackChunkName: "components/clinic-menu" */).then(c => wrapFunctional(c.default || c))
export const ClinicSummary = () => import('../../components/clinicSummary.vue' /* webpackChunkName: "components/clinic-summary" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/contactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const Cookies = () => import('../../components/cookies.vue' /* webpackChunkName: "components/cookies" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Intermission = () => import('../../components/intermission.vue' /* webpackChunkName: "components/intermission" */).then(c => wrapFunctional(c.default || c))
export const Login = () => import('../../components/login.vue' /* webpackChunkName: "components/login" */).then(c => wrapFunctional(c.default || c))
export const LoginBar = () => import('../../components/loginBar.vue' /* webpackChunkName: "components/login-bar" */).then(c => wrapFunctional(c.default || c))
export const LoginComponent = () => import('../../components/loginComponent.vue' /* webpackChunkName: "components/login-component" */).then(c => wrapFunctional(c.default || c))
export const LoginModal = () => import('../../components/loginModal.vue' /* webpackChunkName: "components/login-modal" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../components/map.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../components/menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const MenuItem = () => import('../../components/menuItem.vue' /* webpackChunkName: "components/menu-item" */).then(c => wrapFunctional(c.default || c))
export const NavMain = () => import('../../components/navMain.vue' /* webpackChunkName: "components/nav-main" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const Post = () => import('../../components/post.vue' /* webpackChunkName: "components/post" */).then(c => wrapFunctional(c.default || c))
export const RecipeSummary = () => import('../../components/recipeSummary.vue' /* webpackChunkName: "components/recipe-summary" */).then(c => wrapFunctional(c.default || c))
export const RecipesCategories = () => import('../../components/recipesCategories.vue' /* webpackChunkName: "components/recipes-categories" */).then(c => wrapFunctional(c.default || c))
export const ResultGroup = () => import('../../components/resultGroup.vue' /* webpackChunkName: "components/result-group" */).then(c => wrapFunctional(c.default || c))
export const ResultItem = () => import('../../components/resultItem.vue' /* webpackChunkName: "components/result-item" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const SearchAlgolia = () => import('../../components/searchAlgolia.vue' /* webpackChunkName: "components/search-algolia" */).then(c => wrapFunctional(c.default || c))
export const SearchHint = () => import('../../components/searchHint.vue' /* webpackChunkName: "components/search-hint" */).then(c => wrapFunctional(c.default || c))
export const Staff = () => import('../../components/staff.vue' /* webpackChunkName: "components/staff" */).then(c => wrapFunctional(c.default || c))
export const Testimonial = () => import('../../components/testimonial.vue' /* webpackChunkName: "components/testimonial" */).then(c => wrapFunctional(c.default || c))
export const TopBanner = () => import('../../components/topBanner.vue' /* webpackChunkName: "components/top-banner" */).then(c => wrapFunctional(c.default || c))
export const UserLinks = () => import('../../components/userLinks.vue' /* webpackChunkName: "components/user-links" */).then(c => wrapFunctional(c.default || c))
export const BlocksCards = () => import('../../components/blocks/cards.vue' /* webpackChunkName: "components/blocks-cards" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentPanel = () => import('../../components/blocks/contentPanel.vue' /* webpackChunkName: "components/blocks-content-panel" */).then(c => wrapFunctional(c.default || c))
export const BlocksCtaSmall = () => import('../../components/blocks/ctaSmall.vue' /* webpackChunkName: "components/blocks-cta-small" */).then(c => wrapFunctional(c.default || c))
export const BlocksCustomBlock = () => import('../../components/blocks/customBlock.vue' /* webpackChunkName: "components/blocks-custom-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksFaq = () => import('../../components/blocks/faq.vue' /* webpackChunkName: "components/blocks-faq" */).then(c => wrapFunctional(c.default || c))
export const BlocksForm = () => import('../../components/blocks/form.vue' /* webpackChunkName: "components/blocks-form" */).then(c => wrapFunctional(c.default || c))
export const BlocksGallery = () => import('../../components/blocks/gallery.vue' /* webpackChunkName: "components/blocks-gallery" */).then(c => wrapFunctional(c.default || c))
export const BlocksGalleryItem = () => import('../../components/blocks/galleryItem.vue' /* webpackChunkName: "components/blocks-gallery-item" */).then(c => wrapFunctional(c.default || c))
export const BlocksHero = () => import('../../components/blocks/hero.vue' /* webpackChunkName: "components/blocks-hero" */).then(c => wrapFunctional(c.default || c))
export const BlocksHeroAlt = () => import('../../components/blocks/heroAlt.vue' /* webpackChunkName: "components/blocks-hero-alt" */).then(c => wrapFunctional(c.default || c))
export const BlocksLinks = () => import('../../components/blocks/links.vue' /* webpackChunkName: "components/blocks-links" */).then(c => wrapFunctional(c.default || c))
export const BlocksList = () => import('../../components/blocks/list.vue' /* webpackChunkName: "components/blocks-list" */).then(c => wrapFunctional(c.default || c))
export const BlocksNews = () => import('../../components/blocks/news.vue' /* webpackChunkName: "components/blocks-news" */).then(c => wrapFunctional(c.default || c))
export const BlocksNewsItem = () => import('../../components/blocks/newsItem.vue' /* webpackChunkName: "components/blocks-news-item" */).then(c => wrapFunctional(c.default || c))
export const BlocksNewsletter = () => import('../../components/blocks/newsletter.vue' /* webpackChunkName: "components/blocks-newsletter" */).then(c => wrapFunctional(c.default || c))
export const BlocksProductSlider = () => import('../../components/blocks/productSlider.vue' /* webpackChunkName: "components/blocks-product-slider" */).then(c => wrapFunctional(c.default || c))
export const BlocksProductSliderItem = () => import('../../components/blocks/productSliderItem.vue' /* webpackChunkName: "components/blocks-product-slider-item" */).then(c => wrapFunctional(c.default || c))
export const BlocksPromo = () => import('../../components/blocks/promo.vue' /* webpackChunkName: "components/blocks-promo" */).then(c => wrapFunctional(c.default || c))
export const BlocksPromoNew = () => import('../../components/blocks/promoNew.vue' /* webpackChunkName: "components/blocks-promo-new" */).then(c => wrapFunctional(c.default || c))
export const BlocksQuoteBlock = () => import('../../components/blocks/quoteBlock.vue' /* webpackChunkName: "components/blocks-quote-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksSlider = () => import('../../components/blocks/slider.vue' /* webpackChunkName: "components/blocks-slider" */).then(c => wrapFunctional(c.default || c))
export const BlocksTestimonial = () => import('../../components/blocks/testimonial.vue' /* webpackChunkName: "components/blocks-testimonial" */).then(c => wrapFunctional(c.default || c))
export const BlocksTestimonials = () => import('../../components/blocks/testimonials.vue' /* webpackChunkName: "components/blocks-testimonials" */).then(c => wrapFunctional(c.default || c))
export const BlocksVideoBlock = () => import('../../components/blocks/videoBlock.vue' /* webpackChunkName: "components/blocks-video-block" */).then(c => wrapFunctional(c.default || c))
export const Errors = () => import('../../components/errors/404.vue' /* webpackChunkName: "components/errors" */).then(c => wrapFunctional(c.default || c))
export const ShopAddress = () => import('../../components/shop/address.vue' /* webpackChunkName: "components/shop-address" */).then(c => wrapFunctional(c.default || c))
export const ShopAddressSummary = () => import('../../components/shop/addressSummary.vue' /* webpackChunkName: "components/shop-address-summary" */).then(c => wrapFunctional(c.default || c))
export const ShopBreadcrumbs = () => import('../../components/shop/breadcrumbs.vue' /* webpackChunkName: "components/shop-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const ShopCart = () => import('../../components/shop/cart.vue' /* webpackChunkName: "components/shop-cart" */).then(c => wrapFunctional(c.default || c))
export const ShopCartItem = () => import('../../components/shop/cartItem.vue' /* webpackChunkName: "components/shop-cart-item" */).then(c => wrapFunctional(c.default || c))
export const ShopCategoryList = () => import('../../components/shop/categoryList.vue' /* webpackChunkName: "components/shop-category-list" */).then(c => wrapFunctional(c.default || c))
export const ShopCoupon = () => import('../../components/shop/coupon.vue' /* webpackChunkName: "components/shop-coupon" */).then(c => wrapFunctional(c.default || c))
export const ShopHiPay = () => import('../../components/shop/hiPay.vue' /* webpackChunkName: "components/shop-hi-pay" */).then(c => wrapFunctional(c.default || c))
export const ShopLevPoints = () => import('../../components/shop/levPoints.vue' /* webpackChunkName: "components/shop-lev-points" */).then(c => wrapFunctional(c.default || c))
export const ShopMbWay = () => import('../../components/shop/mbWay.vue' /* webpackChunkName: "components/shop-mb-way" */).then(c => wrapFunctional(c.default || c))
export const ShopMbwayPending = () => import('../../components/shop/mbwayPending.vue' /* webpackChunkName: "components/shop-mbway-pending" */).then(c => wrapFunctional(c.default || c))
export const ShopMultibanco = () => import('../../components/shop/multibanco.vue' /* webpackChunkName: "components/shop-multibanco" */).then(c => wrapFunctional(c.default || c))
export const ShopMyProducts = () => import('../../components/shop/myProducts.vue' /* webpackChunkName: "components/shop-my-products" */).then(c => wrapFunctional(c.default || c))
export const ShopNewReview = () => import('../../components/shop/newReview.vue' /* webpackChunkName: "components/shop-new-review" */).then(c => wrapFunctional(c.default || c))
export const ShopOrderRow = () => import('../../components/shop/orderRow.vue' /* webpackChunkName: "components/shop-order-row" */).then(c => wrapFunctional(c.default || c))
export const ShopOrderSummary = () => import('../../components/shop/orderSummary.vue' /* webpackChunkName: "components/shop-order-summary" */).then(c => wrapFunctional(c.default || c))
export const ShopPaymentMethods = () => import('../../components/shop/paymentMethods.vue' /* webpackChunkName: "components/shop-payment-methods" */).then(c => wrapFunctional(c.default || c))
export const ShopProduct = () => import('../../components/shop/product.vue' /* webpackChunkName: "components/shop-product" */).then(c => wrapFunctional(c.default || c))
export const ShopProductTags = () => import('../../components/shop/productTags.vue' /* webpackChunkName: "components/shop-product-tags" */).then(c => wrapFunctional(c.default || c))
export const ShopReview = () => import('../../components/shop/review.vue' /* webpackChunkName: "components/shop-review" */).then(c => wrapFunctional(c.default || c))
export const ShopReviews = () => import('../../components/shop/reviews.vue' /* webpackChunkName: "components/shop-reviews" */).then(c => wrapFunctional(c.default || c))
export const ShopRow = () => import('../../components/shop/row.vue' /* webpackChunkName: "components/shop-row" */).then(c => wrapFunctional(c.default || c))
export const ShopShippingLeft = () => import('../../components/shop/shippingLeft.vue' /* webpackChunkName: "components/shop-shipping-left" */).then(c => wrapFunctional(c.default || c))
export const ShopCategories = () => import('../../components/shop/shopCategories.vue' /* webpackChunkName: "components/shop-categories" */).then(c => wrapFunctional(c.default || c))
export const ShopMenu = () => import('../../components/shop/shopMenu.vue' /* webpackChunkName: "components/shop-menu" */).then(c => wrapFunctional(c.default || c))
export const ShopMenuCategory = () => import('../../components/shop/shopMenuCategory.vue' /* webpackChunkName: "components/shop-menu-category" */).then(c => wrapFunctional(c.default || c))
export const ShopSidebar = () => import('../../components/shop/sidebar.vue' /* webpackChunkName: "components/shop-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ShopSort = () => import('../../components/shop/sort.vue' /* webpackChunkName: "components/shop-sort" */).then(c => wrapFunctional(c.default || c))
export const ShopSortItem = () => import('../../components/shop/sortItem.vue' /* webpackChunkName: "components/shop-sort-item" */).then(c => wrapFunctional(c.default || c))
export const ShopTagsFilter = () => import('../../components/shop/tagsFilter.vue' /* webpackChunkName: "components/shop-tags-filter" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
