
import { mapState } from 'vuex';

export default {
  name: 'Row',

  props: {
    price: {
      type: [Number, String],
      default: 0,
    },
    label: {
      type: String,
      default: null,
    },
    grey: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    shipping: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(['currency']),
  },
};
