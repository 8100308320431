
import { mapMutations } from 'vuex';
import PrimaryButton from '@/components/ui/primaryButton.vue';

export default {
  name: 'MbwayPending',

  components: {
    PrimaryButton,
  },

  data() {
    return {
      time: 400000,
      interval: null,
    };
  },

  computed: {
    timer() {
      const minutes = Math.floor(this.time / 60000);
      const seconds = ((this.time % 60000) / 1000).toFixed(0);
      return `${minutes} : ${seconds < 10 ? '0' : ''}${seconds}`;
    },
  },

  watch: {
    time(value) {
      if (value < 1000) {
        clearInterval(this.interval);
        this.setMbwayPending(false);
      }
    },
  },

  beforeMount() {
    document.body.classList.add('no-scroll');
  },

  mounted() {
    this.startTimer();
  },

  beforeDestroy() {
    document.body.classList.remove('no-scroll');
  },

  methods: {
    ...mapMutations(['setMbwayPending']),

    startTimer() {
      this.interval = setInterval(() => {
        this.time = this.time - 1000;
      }, 1000);
    },
  },
};
