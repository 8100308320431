
import { mapMutations, mapState } from 'vuex';
import Vue from 'vue';
import layoutMixin from '@/mixins/layout.js';
import NavMain from '@/components/navMain';
import Footer from '@/components/footer';
import PromoNew from '@/components/blocks/promoNew.vue';
import ChatButton from '@/components/chatButton';
import MbwayPending from '@/components/shop/mbwayPending.vue';
import global from '@/mixins/global';

Vue.mixin(global);

export default {
  name: 'Layout',

  components: {
    NavMain,
    Footer,
    PromoNew,
    ChatButton,
    MbwayPending,
  },

  mixins: [layoutMixin],

  data() {
    return {
      targetPicker: false,
      loaded: false,
    };
  },

  computed: {
    ...mapState(['loginOpened']),
  },

  mounted() {
    this.checkCookies();
    setTimeout(() => {
      this.loaded = true;
    }, 4000);
  },

  methods: {
    ...mapMutations(['closeLoginModal']),

    closeTargetPicker() {
      this.targetPicker = false;
    },

    navigateHome(cookieName) {
      const values = document.cookie.split(';');
      let value = null;
      for (let i = 0; i < values.length; i++) {
        const name = values[i].split('=')[0];
        if (name.includes(cookieName)) {
          value = values[i].split('=')[1];
        }
      }
      if (value) {
        // this.$router.push(value.replace(this.url, ''));
      } else {
        this.closeTargetPicker();
      }
    },

    checkCookies() {
      if (!document.cookie.includes('lev_promo_session=shown')) {
        this.targetPicker = true;
      } else {
        this.targetPicker = false;
      }
    },
  },
};
