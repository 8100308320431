import Vue from 'vue';

const animateOnScrollObserver = new IntersectionObserver(
  (entries, animateOnScrollObserver) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('enter');
        animateOnScrollObserver.unobserve(entry.target);
      }
    });
  }
);

Vue.directive('animate-scroll', {
  bind: (el) => {
    el.classList.add('before-enter');
    setTimeout(() => {
      animateOnScrollObserver.observe(el);
    }, 100);
  },
});
