
import { mapState } from 'vuex';
import CategoryList from '@/components/shop/categoryList.vue';
import ProductTags from '@/components/shop/productTags.vue';
import MyProducts from '@/components/shop/myProducts';

export default {
  name: 'Categorylist',

  components: {
    CategoryList,
    ProductTags,
    MyProducts,
  },

  data() {
    return {
      openCategory: 0,
    };
  },

  computed: {
    ...mapState(['categories', 'tags']),

    topLevelCategories() {
      return this.categories
        .filter((c) => c.parent === 0)
        .sort((a, b) => a.menu_order - b.menu_order);
    },
  },
  methods: {
    openClose(id) {
      if (this.openCategory === id) {
        this.openCategory = 0;
      } else this.openCategory = id;
    },
  },
};
