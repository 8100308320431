import { render, staticRenderFns } from "./product.vue?vue&type=template&id=364d7187"
import script from "./product.vue?vue&type=script&lang=js"
export * from "./product.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavMain: require('/vercel/path0/components/navMain.vue').default,ShopMenu: require('/vercel/path0/components/shop/shopMenu.vue').default,Footer: require('/vercel/path0/components/footer.vue').default,LoginModal: require('/vercel/path0/components/loginModal.vue').default})
