
import { mapState } from 'vuex';
import ProductTags from '@/components/shop/productTags.vue';

export default {
  name: 'TagsFilter',

  components: {
    ProductTags,
  },

  computed: {
    ...mapState(['tags']),
  },
};
