
import { mapState, mapMutations } from 'vuex';
import UserLinks from '@/components/userLinks';

export default {
  name: 'MyProducts',

  components: {
    UserLinks,
  },

  data() {
    return {
      dropdownOpened: true,
      isMobile: false,
    };
  },

  computed: {
    ...mapState(['user', 'loginOpened']),
  },

  mounted() {
    const routes = [
      '/my-page',
      '/my-products',
      '/my-favorites',
      '/my-watchlist',
    ];
    if (routes.includes(this.$route.path)) {
      this.dropdownOpened = true;
    }
    this.checkIfMobile();
    if (this.isMobile) {
      this.dropdownOpened = false;
    }
  },

  methods: {
    ...mapMutations(['openLoginModal']),

    test() {
      this.openLoginModal();
    },

    toggleDropdown() {
      this.dropdownOpened = !this.dropdownOpened;
    },

    checkIfMobile() {
      if (window && window.innerWidth < 720) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
};
