export const state = () => ({
  menuOpened: false,
  cartOpened: false,
  products: [],
  posts: [],
  token: null,
  user: null,
  myProducts: [],
  myFavorites: [],
  favoritesCount: null,
  myWatchlist: [],
  patientId: null,
  menu: null,
  currency: '€',
  prices: [],
  tags: [],
  categories: [],
  tagsFilter: [],
  categoriesFilter: [],
  sortCriteria: null,
  sortName: null,
  cart: [],
  order: {},
  orderId: null,
  // savedCards: [],
  marketing: true,
  terms: false,
  contactFormOpened: false,
  chatFormOpened: false,
  chatFormSent: false,
  clinics: [],
  gmapLoaded: false,
  staff: [],
  recipes: [],
  recipeCategories: [],
  blogCategories: [],
  isPost: false,
  closestClinicId: null,
  deliveryAddress: [],
  options: [],
  loginMessage: null,
  loginOpened: false,
  testimonials: [],
  // activeCard: null,
  mbDetails: null,
  filterOpen: false,
  inShop: false,
  method: 'multibanco',
  mbwayPending: false,
  cookies: false,
  usePoints: false,
  shippingLeft: undefined,
  calculatedShipping: null,
  calculatedZip: null,
  utm: {},
  promoOpened: true,
  checkoutStep: 1,
  removedItems: [],
  updatedItems: [],
});

export const mutations = {
  addRemovedItem(state, item) {
    if (!state.removedItems.find((p) => p.sku === item.sku)) {
      state.removedItems.push(item);
    }
  },

  addUpdatedItem(state, item) {
    if (!state.updatedItems.find((p) => p.sku === item.sku)) {
      state.updatedItems.push(item);
    }
  },

  clearRemovedItems(state) {
    state.removedItems = [];
  },

  clearUpdatedItems(state) {
    state.updatedItems = [];
  },

  setMethod: (state, value) => {
    state.method = value;
  },

  openLoginModal: (state) => {
    state.loginOpened = true;
  },

  closeLoginModal: (state) => {
    state.loginOpened = false;
  },

  setCheckoutStep: (state, value) => {
    state.checkoutStep = value;
  },

  setUtmParams: (state, params) => {
    state.utm = params;
  },

  setMbwayPending: (state, data) => (state.mbwayPending = data),

  openMenu: (state) => (state.menuOpened = true),

  closeMenu: (state) => (state.menuOpened = false),

  toggleMenu: (state) => (state.menuOpened = !state.menuOpened),

  updateFavoritesCount: (state, data) => {
    if (data) {
      state.favoritesCount = state.favoritesCount + data;
    } else {
      state.favoritesCount = state.myFavorites.length;
    }
  },

  updateFavorites: (state, sku) => {
    const index = state.myFavorites.findIndex((f) => f.sage_id === sku);

    if (index !== -1) {
      // remove from the favorites
      state.myFavorites.splice(index, 1);
    } else {
      // add to the favorites
      state.myFavorites.push({ sage_id: sku });
    }
  },

  addTestimonial: (state, data) => {
    if (!state.testimonials.map((c) => c.id).includes(data.id)) {
      state.testimonials.push(data);
    }
  },

  turnOffCookies: (state) => (state.cookies = true),

  setInShop: (state) => (state.inShop = true),

  setNotInShop: (state) => (state.inShop = false),

  closeFilter: (state) => (state.filterOpen = false),

  toggleFilter: (state) => (state.filterOpen = !state.filterOpen),

  setIsPost: (state, value) => (state.isPost = value),

  setTestimonials: (state, data) => (state.testimonials = data),

  setClosestClinic: (state, id) => (state.closestClinicId = id),

  setRecipeCategories: (state, data) => (state.recipeCategories = data),

  setBlogCategories: (state, data) => (state.blogCategories = data),

  setStaff: (state, data) => (state.staff = data),

  markGmapAsLoaded: (state) => (state.gmapLoaded = true),

  openContactForm: (state) => (state.contactFormOpened = true),

  closeContactForm: (state) => (state.contactFormOpened = false),

  toggleContactForm: (state) =>
    (state.contactFormOpened = !state.contactFormOpened),

  openChatForm: (state) => (state.chatFormOpened = true),

  closeChatForm: (state) => (state.chatFormOpened = false),

  toggleChatForm: (state) => (state.chatFormOpened = !state.chatFormOpened),

  openPromo: (state) => (state.promoOpened = true),

  closePromo: (state) => (state.promoOpened = false),

  togglePromo: (state) => (state.promoOpened = !state.promoOpened),

  openCart: (state) => {
    state.cartOpened = true;
    document.querySelector(':root').classList.add('no-scroll');
  },

  closeCart: (state) => {
    state.cartOpened = false;
    document.querySelector(':root').classList.remove('no-scroll');
  },

  toggleCart: (state) => (state.cartOpened = !state.chatFormOpened),

  setChatFormSent: (state) => (state.chatFormSent = true),

  setChatFormUnsent: (state) => (state.chatFormSent = false),

  setRecipes: (state, data) => {
    const recipes = [...state.recipes, ...data];
    state.recipes = recipes.filter(
      (value, index, array) =>
        array.findIndex((r) => r.id === value.id) === index
    );
  },

  addRecipe(state, data) {
    if (!state.recipes.map((c) => c.id).includes(data.id)) {
      state.recipes.push(data);
    }
  },

  setClinics: (state, data) => {
    state.clinics = data.sort((a, b) => {
      return new Intl.Collator(process.env.VUE_APP_LANG).compare(
        a.title.rendered,
        b.title.rendered
      );
    });
  },

  addClinic(state, data) {
    if (!state.clinics.map((c) => c.id).includes(data.id)) {
      state.clinics.push(data);
    }
  },

  setLoginMessage(state, message) {
    state.loginMessage = message;
  },

  setProducts(state, data) {
    const products = [
      ...state.products,
      ...data.filter((p) => state.prices.find((sp) => p.sku === sp.sku)),
    ];
    state.products = products.filter(
      (value, index, array) =>
        array.findIndex((p) => p.id === value.id) === index
    );
  },

  setMyProducts(state, data) {
    const myProducts = [...state.myProducts, ...data];
    state.myProducts = myProducts.filter(
      (value, index, array) =>
        array.findIndex((p) => p.id === value.id) === index
    );
  },

  addProduct(state, product) {
    if (!state.products.find((p) => p.id === product.id)) {
      state.products = [...state.products, product];
    }
  },

  setOptions(state, value) {
    state.options = value;
  },

  setMarketing(state, value) {
    state.marketing = value;
  },

  setTerms(state, value) {
    state.terms = value;
  },

  setMbDetails(state, value) {
    state.mbDetails = value;
  },

  setPosts(state, data) {
    const posts = [...state.posts, ...data];
    state.posts = posts.filter(
      (value, index, array) =>
        array.findIndex((p) => p.id === value.id) === index
    );
  },

  setToken(state, token) {
    state.token = token;
  },

  setUser(state, data) {
    state.user = data ? data.user : null;
    state.myProducts = data ? data.myProducts : [];
    state.myFavorites = data && data.favorites ? data.favorites : [];
    state.myWatchlist = data && data.watched ? data.watched : [];
    state.favoritesCount = state.myFavorites.length;
  },

  setDeliveryAddress(state, deliveryAddress) {
    state.deliveryAddress = deliveryAddress;
  },

  addDeliveryAddress(state, address) {
    state.deliveryAddress = [];
    state.deliveryAddress.push(address);
  },

  setShippingLeft(state, value) {
    state.shippingLeft = parseFloat(value);
  },

  setCalculatedZip(state, value) {
    state.calculatedZip = value;
  },

  setCalculatedShipping(state, value) {
    state.calculatedShipping = value;
  },

  setPatientId(state, id) {
    state.patientId = id;
  },

  setMenu(state, menu) {
    state.menu = menu;
  },

  setPrices(state, prices) {
    state.prices = [...prices];
  },

  setCategories(state, categories) {
    state.categories = [...categories];
  },

  setTags(state, tags) {
    state.tags = [...tags];
  },

  addTagFilter(state, tag) {
    state.tagsFilter.push(tag);
  },

  removeTagFilter(state, tag) {
    const index = state.tagsFilter.findIndex((t) => t === tag);
    if (index > -1) {
      state.tagsFilter.splice(index, 1);
    }
  },

  resetTagFilter(state) {
    state.tagsFilter = [];
  },

  addCategoryFilter(state, category) {
    state.categoriesFilter.push(category);
  },

  removeCategoryFilter(state, category) {
    const index = state.categoriesFilter.findIndex((c) => c === category);
    if (index > -1) {
      state.categoriesFilter.splice(index, 1);
    }
  },

  resetCategoryFilter(state) {
    state.categoriesFilter = [];
  },

  setSortCriteria(state, criteria) {
    if (criteria) {
      state.sortCriteria = criteria.type;
      state.sortName = criteria.name;
    } else {
      state.sortCriteria = null;
      state.sortName = null;
    }
  },

  clearCart(state) {
    state.cart = [];
  },

  clearOrder(state) {
    state.order = {};
    state.orderId = null;
  },

  editCartItem(state, { quantity, sku }) {
    const index = state.cart.findIndex((r) => r.sku === sku);
    if (index > -1) {
      state.cart[index].quantity = quantity;
    }
  },

  removeCartItem(state, sku) {
    const index = state.cart.findIndex((r) => r.sku === sku);
    if (index > -1) {
      state.cart.splice(index, 1);
    }
  },

  addToCart(state, { quantity, sku, price, regularPrice, salePrice, onSale }) {
    const index = state.cart.findIndex((r) => r.sku === sku);
    if (index > -1) {
      state.cart[index].quantity += quantity;
    } else {
      state.cart.push({
        quantity,
        sku,
        on_sale: onSale,
        unit_price: price,
        regular_price: parseFloat(regularPrice),
        sale_price: salePrice ? parseFloat(salePrice) : null,
      });
    }
  },

  setOrder(state, order) {
    state.order = { ...order };
  },

  setOrderId(state, orderId) {
    state.orderId = orderId;
  },

  updateDeliveryAddresses(state, address) {
    state.deliveryAddress = state.deliveryAddress.map((a) => {
      const addressToChange = state.deliveryAddress.find(
        (a) => a.id === address.id
      );
      return addressToChange ? address : a;
    });
  },

  setPoints(state, value) {
    state.usePoints = value;
  },
};

export const getters = {
  getClinic: (state) => (slug) => state.clinics.find((c) => c.slug === slug),

  getRecipe: (state) => (slug) => state.recipes.find((c) => c.slug === slug),

  getStaff: (state) => (id) => state.staff.find((s) => s.id === id),

  subTotal: (state) => {
    const sum = state.cart.reduce((acc, curr) => {
      acc += curr.on_sale
        ? parseFloat(curr.sale_price).toFixed(2) * curr.quantity
        : parseFloat(curr.regular_price).toFixed(2) * curr.quantity;
      return acc;
    }, 0);
    return parseFloat(sum.toFixed(2));
  },

  cartQuantity: (state) => {
    return state.cart.reduce((acc, curr) => (acc += curr.quantity), 0);
  },

  getProductBg: (state) => (sku) => {
    const product = state.products.find((p) => p.sku === sku);
    return product ? product.acf.background_color : null;
  },

  getProductCategoryName: (state) => (sku) => {
    const product = state.products.find((p) => p.sku === sku);
    return product?.categories?.length
      ? product.categories[0].name.replace(/&amp;/gi, '&')
      : null;
  },

  getQuantity: (state) => (sku) => {
    const row = state.cart.find((r) => r.sku === sku);
    return row ? row.quantity : 0;
  },

  getPrice: (state) => (sku) => {
    const price = state.prices.find((p) => p.sku === sku);
    return price ? Number(price.price) : null;
  },

  getActualPrice: (state, getters) => (sku) => {
    const product = state.products.find((p) => p.sku === sku);
    if (product && product.on_sale) {
      return Number(product.sale_price);
    }
    return getters.getPrice(sku);
  },

  getProductImages: (state) => (sku) => {
    const product = state.products.find((p) => p.sku === sku);
    return product ? product.images : [];
  },

  getProductName: (state) => (sku) => {
    const product = state.products.find((p) => p.sku === sku);
    return product ? product.name : null;
  },

  getProductBrand: (state) => (sku) => {
    const product = state.products.find((p) => p.sku === sku);
    const brand = product.attributes.find((a) => a.name === 'brand');
    return brand || 'Lev';
  },

  getProductSlug: (state) => (sku) => {
    const product = state.products.find((p) => p.sku === sku);
    return product ? product.slug : null;
  },

  getStock: (state) => (sku) => {
    const price = state.prices.find((p) => p.sku === sku);
    return price && price.product
      ? {
          in_stock: price.product.in_stock,
          low_stock: price.product.low_stock,
          stock: price.product.stock,
        }
      : null;
  },

  getCategoryBySlug: (state) => (slug) => {
    return state.categories.find((c) => c.slug === slug);
  },

  getBlogCategoryBySlug: (state) => (slug) => {
    return state.blogCategories.find((c) => c.slug === slug);
  },

  filterProducts: (state) => (products) => {
    const filteredByCategories =
      state.categoriesFilter.length === 0
        ? [...products]
        : [
            ...products.filter((p) =>
              p.categories.some((c) => state.categoriesFilter.includes(c.id))
            ),
          ];
    const filteredByTags =
      state.tagsFilter.length === 0
        ? [...filteredByCategories]
        : [
            ...filteredByCategories.filter((p) =>
              p.tags.some((t) => state.tagsFilter.includes(t.id))
            ),
          ];
    return filteredByTags;
  },

  sortProducts: (state, getters) => (products) => {
    if (state.sortCriteria === null) {
      return [...products.sort((a, b) => (a.featured && !b.featured ? -1 : 0))];
    }
    let sortedProducts;
    const sortDirection = state.sortCriteria.split('_')[1];
    const criteria = state.sortCriteria.split('_')[0];
    if (criteria === 'name') {
      sortedProducts = [
        ...products.sort((a, b) => {
          return new Intl.Collator(process.env.VUE_APP_LANG).compare(
            a.name,
            b.name
          );
        }),
      ];
    } else if (criteria === 'price') {
      sortedProducts = [
        ...products.sort(
          (a, b) =>
            getters.getActualPrice(a.sku) - getters.getActualPrice(b.sku)
        ),
      ];
    } else {
      sortedProducts = [...products];
    }
    return sortDirection === 'asc'
      ? [...sortedProducts]
      : [...sortedProducts].reverse();
  },
};

export const actions = {
  gtEvent: (_, event) => {
    if (process.env.NODE_ENV !== 'development')
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'tracking',
        eventAction: event.action,
        eventLabel: event.label ? event.label : null,
        eventValue: event.value ? event.value : null,
      });
    else {
      console.log('googleEvent', event);
    }
  },

  async updateOrder({ commit, dispatch, state }) {
    if (state.cart.length === 0) {
      commit('clearOrder');
      return;
    }
    if (state.orderId === null) return;
    try {
      const { data } = await this.app.$bolev.updateOrder({
        rows: state.cart,
        patientId: state.patientId,
        orderId: state.orderId,
      });
      if (data.status > 3) {
        dispatch('createOrder');
      } else {
        commit('setOrder', data);
        dispatch('calculateShipping', data);
      }
      if (state.usePoints) {
        dispatch('togglePoints', false);
      }
    } catch (e) {
      commit('clearOrder');
      return null;
    }
  },

  async createOrder({ commit, dispatch, state }) {
    if (state.cart.length === 0) return;
    try {
      const { data } = await this.app.$bolev.createOrder({
        rows: state.cart,
        patientId: state.patientId,
        source: 'Web',
      });
      commit('setOrder', data);
      commit('setOrderId', data.id);
      dispatch('calculateShipping', data);
      return data;
    } catch (e) {
      commit('clearOrder', {});
      return null;
    }
  },

  async getOrder({ commit, state, dispatch }) {
    try {
      const { data } = await this.app.$bolev.getOrder({
        orderId: state.orderId,
        patientId: state.patientId,
      });
      if (data.status > 3) {
        dispatch('createOrder');
      } else {
        commit('setOrder', data);
        commit('setOrderId', data.id);
        dispatch('calculateShipping', data);
      }
      return data;
    } catch (e) {
      commit('clearOrder');
      return null;
    }
  },

  async calculateShipping({ commit, state, getters }, order) {
    if (order.address) {
      try {
        const { data } = await this.$bolev.getShipping({
          zip: order.address.zip,
          total: getters.subTotal,
          patientId: state.patientId,
        });
        commit('setCalculatedShipping', parseFloat(data.shipping));
        commit('setShippingLeft', data.left);
      } catch (error) {
        console.log(error);
      }
    }
  },

  async togglePoints({ commit, state }, value) {
    try {
      const { data } = await this.$bolev.levPoints({
        levPoints: value,
        orderId: state.order.id,
      });
      commit('setOrder', data);
      commit('setPoints', value);
    } catch (error) {
      console.log(error);
    }
  },
};
