export default ({ app, $axios }, inject) => {
  const apiCall = ({
    endpoint,
    id,
    slug,
    payload,
    method,
    namespace,
    perPage,
    page,
    categories,
    search,
    query,
    postType,
  }) => {
    method = method || 'get';
    namespace = namespace || 'wp/v2';
    perPage = perPage || 100;
    page = page || 1;
    let fragment;
    if (id) {
      fragment = `${endpoint}/${id}`;
    } else if (query) {
      fragment = `${endpoint}/${query}`;
    } else {
      fragment = endpoint;
    }
    let params = '';
    if (search) {
      params = `search=${search}&per_page=${perPage}&page=${page}&subtype=${postType.join(
        ','
      )}`;
    } else if (slug) {
      params = `slug=${slug}`;
    } else if (id) {
      params = `id=${id}&_embed`;
    } else if (categories) {
      params = `categories=${categories}&per_page=${perPage}&page=${page}`;
    } else if (!query) {
      params = `per_page=${perPage}&page=${page}`;
    }
    return $axios({
      method,
      url: `${process.env.WORDPRESS_URL}/wp-json/${namespace}/${fragment}?${params}`,
      data: payload,
    });
  };

  inject('wp', {
    posts: (params) => apiCall({ endpoint: 'posts', ...params }),
    promotions: (params) => apiCall({ endpoint: 'promotion', ...params }),
    frontpage: () => apiCall({ endpoint: 'frontpage', namespace: 'lev' }),
    pages: (params) => apiCall({ endpoint: 'pages', ...params }),
    categories: (params) => apiCall({ endpoint: 'categories', ...params }),
    tags: (params) => apiCall({ endpoint: 'tags', ...params }),
    medias: (params) => apiCall({ endpoint: 'medias', ...params }),
    menu: () => apiCall({ endpoint: 'menu', namespace: 'lev' }),
    slugs: () => apiCall({ endpoint: 'slugs', namespace: 'lev' }),
    options: () => apiCall({ endpoint: 'options', namespace: 'lev' }),
    clinics: (params) => apiCall({ endpoint: 'clinic', ...params }),
    recipes: (params) => apiCall({ endpoint: 'recipe', ...params }),
    staff: (params) => apiCall({ endpoint: 'staff', ...params }),
    recipeCategories: () =>
      apiCall({ endpoint: 'recipeCategories', namespace: 'lev' }),
    testimonials: (params) => apiCall({ endpoint: 'testimonial', ...params }),
    search: (params) => apiCall({ endpoint: 'search', ...params }),
    getSuggestions: (params) =>
      apiCall({ endpoint: 'suggestions', namespace: 'lev', ...params }),
    page: (params) =>
      apiCall({ endpoint: 'page', namespace: 'lev', ...params }),
    addToList: (params) =>
      apiCall({
        method: 'post',
        endpoint: 'mailchimp',
        namespace: 'lev',
        payload: params,
      }),
  });
};
