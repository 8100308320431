
import { mapMutations, mapState } from 'vuex';
import SortItem from '@/components/shop/sortItem.vue';

export default {
  name: 'Sort',

  components: {
    SortItem,
  },

  props: {
    mobile: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      criterias: [
        { name: this.$t('shop.sortPrice'), type: 'price_asc' },
        { name: this.$t('shop.sortPriceDesc'), type: 'price_desc' },
        { name: this.$t('shop.sortName'), type: 'name_asc' },
        { name: this.$t('shop.sortNameDesc'), type: 'name_desc' },
      ],
    };
  },

  computed: {
    ...mapState(['sortCriteria', 'sortDirection']),
  },

  watch: {
    sortCriteria() {
      if (!this.mobile) {
        this.$emit('close');
      }
    },
  },

  methods: {
    ...mapMutations(['setSortCriteria', 'setSortDirection']),

    reset() {
      this.setSortCriteria(null);
    },
  },
};
