
export default {
  name: 'ProductTags',

  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
};
