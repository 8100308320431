
export default {
  props: {
    block: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: null,
      error: '',
      subscribed: false,
    };
  },

  watch: {
    email() {
      if (this.error) {
        this.error = '';
      }
    },
  },

  methods: {
    async subscribe() {
      await this.$wp.addToList({ email: this.email }).then((res) => {
        if (res?.data && res.data.status === 'subscribed') {
          this.subscribed = true;
        } else if (res?.data) {
          this.error = res.data.detail;
        }
      });
    },
  },
};
