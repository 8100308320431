
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import CartItem from '@/components/shop/cartItem.vue';
import OrderSummary from '@/components/shop/orderSummary.vue';
import ShippingLeft from '@/components/shop/shippingLeft.vue';

export default {
  name: 'Cart',

  components: {
    CartItem,
    ShippingLeft,
    OrderSummary,
  },

  data() {
    return {
      animate: false,
      showPreview: false,
      error: null,
    };
  },

  computed: {
    ...mapState([
      'cart',
      'orderId',
      'cartOpened',
      'shippingLeft',
      'calculatedShipping',
      'calculatedZip',
    ]),
    ...mapGetters(['subTotal', 'cartQuantity']),

    cartLength() {
      return this.cart.length;
    },
  },

  watch: {
    cartOpened(value) {
      if (value) {
        this.getProducts();
      }
    },
    cartQuantity() {
      this.animate = true;
      setTimeout(() => {
        this.animate = false;
      }, 400);
    },
    cartLength(value, oldValue) {
      if (value > oldValue) {
        this.showPreview = true;
        setTimeout(() => {
          this.showPreview = false;
        }, 3000);
      }
    },
  },
  methods: {
    ...mapMutations([
      'clearCart',
      'clearOrder',
      'setProducts',
      'closeCart',
      'openCart',
      'toggleCart',
      'setCalculatedShipping',
      'setShippingLeft',
    ]),

    ...mapActions(['updateOrder', 'createOrder']),

    async getProducts() {
      const { data } = await this.$wc.products({
        sku: this.cart.map((r) => r.sku),
      });
      this.setProducts(data);
    },

    cancelCloseCart(e) {},

    async updateCart(skipUpdateOrder = false) {
      this.error = null;
      if (this.calculatedZip) {
        try {
          const { data } = await this.$bolev.getShipping({
            zip: this.calculatedZip,
            total: this.subTotal,
          });
          if (!data.shipping.no_sale) {
            this.setCalculatedShipping(data.shipping);
            this.setShippingLeft(data.left);
          }
          if (data.shipping.no_sale) {
            this.error = this.$t('checkout.noDelivery');
          }
        } catch (err) {
          console.log(err);
        }
      }
      if (this.orderId && !skipUpdateOrder) {
        this.updateOrder();
      }
    },

    /* closeCart() {
      this.cartOpened = false;
    }, */

    clearCartAndOrder() {
      this.clearCart();
      this.clearOrder();
    },

    /* toggleCart() {
      this.cartOpened = !this.cartOpened;
    }, */

    toCheckout() {
      if (this.$route.name.startsWith('checkout')) {
        if (this.orderId) {
          this.updateOrder();
        } else {
          this.createOrder();
        }
      } else {
        this.$router.push(this.localePath({ name: 'checkout' }));
      }
      this.closeCart();
    },
  },
};
