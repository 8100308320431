
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['shippingLeft', 'calculatedShipping', 'order']),

    noFreeShipping() {
      return !!(this.shippingLeft === 0 && this.order.shipping.shipping > 0);
    },

    progress() {
      if (this.shippingLeft === undefined || this.order?.shipping?.no_sale) {
        return undefined;
      }
      return this.order?.shipping
        ? `${
            ((this.order.shipping.condition_limit - this.shippingLeft) /
              this.order.shipping.condition_limit) *
            100
          }%`
        : `${
            ((this.calculatedShipping.condition_limit - this.shippingLeft) /
              this.calculatedShipping.condition_limit) *
            100
          }%`;
    },
  },
};
