
import { mapState, mapMutations } from 'vuex';
import LoginModal from '@/components/loginModal.vue';

export default {
  name: 'Login',

  components: {
    LoginModal,
  },

  data() {
    return {
      loginOpened: false,
      showUserLinks: false,
    };
  },

  computed: {
    ...mapState(['user']),

    isLoggedIn() {
      return Boolean(this.user);
    },
  },

  watch: {
    email() {
      this.error = null;
    },
    password() {
      this.error = null;
    },
  },

  mounted() {
    if (this.$route.query.login) {
      this.loginOpened = true;
    }
  },

  methods: {
    ...mapMutations([
      'setUser',
      'setToken',
      'setPatientId',
      'setDeliveryAddress',
      'setShippingLeft',
    ]),

    action() {
      return this.isLoggedIn ? this.logout() : this.toggleLogin();
    },

    closeLogin() {
      this.loginOpened = false;
    },

    toggleLogin() {
      this.loginOpened = !this.loginOpened;
    },

    toggleShowUserLinks() {
      this.showUserLinks = !this.showUserLinks;
    },

    logout() {
      this.setUser(null);
      this.setPatientId(null);
      this.setToken(null);
      this.setDeliveryAddress([]);
      this.setShippingLeft(0);
    },

    login() {
      if (this.email === null || !/(.+)@(.+){2,}\.(.+){2,}/.test(this.email)) {
        this.error = this.$t('forms.invalidMail');
        return false;
      }
      if (this.password === null) {
        this.error = this.$t('forms.invalidPassword');
        return false;
      }
      this.error = null;
      this.$bolev
        .login({
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          this.setToken(res.data.access_token);
          this.$bolev
            .user()
            .then((res) => {
              if (res.data) {
                this.setUser(res.data);
                this.setPatientId(res.data.patientId);
                this.setDeliveryAddress(res.data.deliveryAddress);
                this.closeLogin();
              } else {
                this.error = this.$t('global.error');
              }
            })
            .catch(() => {
              this.setToken(null);
              this.error = this.$t('global.error');
            });
        })
        .catch((err) => {
          if (err.response.status < 500) {
            this.error = this.$t('login.invalidCredentials');
          } else {
            this.error = this.$t('global.error');
          }
        });
    },
  },
};
