import { render, staticRenderFns } from "./singleSelect.vue?vue&type=template&id=4483aebf"
import script from "./singleSelect.vue?vue&type=script&lang=js"
export * from "./singleSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Img: require('/vercel/path0/components/ui/img.vue').default})
