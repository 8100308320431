
import { mapState } from 'vuex';
export default {
  props: {
    category: {
      type: Object,
      default: null,
    },
    openCategory: {
      type: Number,
      default: 0,
    },
  },
  emits: ['open'],
  computed: {
    ...mapState(['categories']),
    secondLevelCategories() {
      return this.categories.filter((c) => c.parent === this.category.id);
    },
  },
  methods: {
    isCurrentCategory(slug) {
      return slug === this.$route.params.slug;
    },

    labelClick() {
      if (this.secondLevelCategories.length > 0) {
        this.$emit('open', this.category.id);
      } else {
        this.$emit('close');
        this.$router.push(
          this.localePath({
            name: 'product-category-slug',
            params: { slug: this.category.slug },
          })
        );
      }
    },

    goToCategory(slug) {
      this.$router.push(
        this.localePath({ name: 'product-category-slug', params: { slug } })
      );
    },

    getHeight(id) {
      if (this.secondLevelCategories.length < 1) {
        return { height: 'auto' };
      } else if (id === this.openCategory) {
        const contentHeight =
          this.$refs.content.clientHeight + this.$refs.label.clientHeight;
        return { height: `${contentHeight}px` };
      } else if (this.$refs.label) {
        return { height: `${this.$refs.label.clientHeight}px` };
      } else return { height: '46px' };
    },
    replace(category) {
      return category.replace(/&amp;/gi, '&');
    },
  },
};
