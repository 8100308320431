
import { mapState, mapMutations } from 'vuex';
import TagsFilter from '@/components/shop/tagsFilter.vue';
import MyProducts from '@/components/shop/myProducts.vue';
import Sort from '@/components/shop/sort.vue';
import CategoryList from '@/components/shop/categoryList.vue';

export default {
  name: 'ShopMenu',

  components: {
    MyProducts,
    TagsFilter,
    Sort,
    CategoryList,
  },

  data() {
    return {
      openCategory: 0,
    };
  },

  computed: {
    ...mapState(['categories', 'filterOpen']),

    mainCategories() {
      return this.categories.filter((c) => c.parent === 0);
    },

    topLevelCategories() {
      return this.categories.filter((c) => c.parent === 0);
    },
  },

  watch: {
    filterOpen(value) {
      value
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'initial');
    },
    $route() {
      this.closeFilter();
    },
  },

  methods: {
    ...mapMutations(['closeFilter', 'toggleFilter']),

    toggleDropdown() {
      this.toggleFilter();
    },

    openClose(id) {
      if (this.openCategory === id) {
        this.openCategory = 0;
      } else this.openCategory = id;
    },
  },
};
