
import { mapGetters, mapState, mapMutations } from 'vuex';
import layoutMixin from '@/mixins/layout.js';
import NavMain from '@/components/navMain.vue';
import Footer from '@/components/footer.vue';
import ShopMenu from '@/components/shop/shopMenu.vue';
import Sidebar from '@/components/shop/sidebar.vue';

export default {
  name: 'ShopLayout',

  components: {
    NavMain,
    Footer,
    ShopMenu,
    Sidebar,
  },

  mixins: [layoutMixin],

  computed: {
    ...mapState(['loginOpened']),
    ...mapGetters(['getCategoryBySlug']),

    hasChildren() {
      return this.category ? Boolean(this.childrenCategories.length) : null;
    },

    parentOrCurrentCat() {
      if (!this.category) {
        return null;
      }
      return this.hasChildren
        ? this.category
        : this.categories.find((c) => c.id === this.category.parent);
    },

    childrenCategories() {
      return this.category
        ? this.categories.filter((c) => c.parent === this.category.id)
        : null;
    },
  },

  methods: {
    ...mapMutations(['closeLoginModal']),
  },
};
