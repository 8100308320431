
import { mapMutations } from 'vuex';
import Link from '@/components/ui/link.vue';

export default {
  name: 'TopBanner',

  components: {
    Link,
  },

  props: {
    banner: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    backgroundColor() {
      return this.banner.banner_background
        ? { 'background-color': this.banner.banner_background }
        : null;
    },
  },

  methods: {
    ...mapMutations(['closeBanner']),
  },
};
