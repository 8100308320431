export default ({ _ }, inject) => {
  const calculateDistance = ({ latitude, longitude, lat2, lon2, unit }) => {
    if (latitude === lat2 && longitude === lon2) {
      return 0;
    } else {
      const radlat1 = (Math.PI * latitude) / 180;
      const radlat2 = (Math.PI * lat2) / 180;
      const theta = longitude - lon2;
      const radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') {
        dist = dist * 1.609344;
      }
      if (unit === 'N') {
        dist = dist * 0.8684;
      }
      return dist;
    }
  };

  inject('geo', {
    closestClinic: ({ latitude, longitude }, clinics) => {
      const distances = clinics.map((c) =>
        calculateDistance({
          latitude,
          longitude,
          lat2: c.fields.address.lat,
          lon2: c.fields.address.lng,
          unit: 'K',
        })
      );
      const closestIndex = distances.indexOf(Math.min(...distances));
      return clinics[closestIndex];
    },
  });
};
