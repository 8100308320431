export default ({ app, $axios }, inject) => {
  const apiCall = ({
    endpoint,
    payload,
    method,
    id,
    page,
    perPage,
    hideEmpty,
    search,
    slug,
    sku,
    tag,
    category,
    ids,
  }) => {
    method = method || 'get';
    perPage = perPage || 100;
    page = page || 1;
    hideEmpty = hideEmpty || true;
    const fragment = id ? `${endpoint}/${id}` : `${endpoint}`;
    let params;
    if (slug) {
      params = `slug=${slug}&catalog_visibility=visible`;
    } else if (sku) {
      params = `sku=${sku.join(
        ','
      )}&per_page=${perPage}&page=${page}&catalog_visibility=visible`;
    } else if (category) {
      params = `category=${category}&per_page=${perPage}&page=${page}&hide_empty=${hideEmpty}&catalog_visibility=visible`;
    } else if (tag) {
      params = `tag=${tag}&per_page=${perPage}&page=${page}&hide_empty=${hideEmpty}&catalog_visibility=visible`;
    } else if (ids) {
      params = `include=${ids.join(
        ','
      )}&per_page=${perPage}&page=${page}&catalog_visibility=visible`;
    } else {
      params = `per_page=${perPage}&page=${page}&hide_empty=${hideEmpty}&catalog_visibility=visible`;
    }
    if (search) {
      params += `&search=${search}`;
    }
    let url = `${process.env.WORDPRESS_URL}/wp-json/wc/v3/${fragment}`;
    if (method === 'get' && !id) {
      url += `?${params}`;
    }
    return $axios({
      method,
      url,
      auth: {
        username: process.env.WOOCOMMERCE_KEY,
        password: process.env.WOOCOMMERCE_SECRET,
      },
      data: payload,
    });
  };

  inject('wc', {
    products: (params) =>
      apiCall({
        endpoint: 'products',
        ...params,
      }),
    categories: (params) =>
      apiCall({
        endpoint: 'products/categories',
        ...params,
      }),
    tags: (params) =>
      apiCall({
        endpoint: 'products/tags',
        ...params,
      }),
    postReview: (params) =>
      apiCall({
        endpoint: 'products/reviews',
        method: 'post',
        ...params,
      }),
    review: (params) =>
      apiCall({
        endpoint: 'products/reviews',
        ...params,
      }),
  });
};
