const redirects = [
  { from: '/loja', to: '/loja-online' },
  { from: '/dieta-lev', to: '/emagrecer' },
  { from: '/a-minha-conta', to: '/loja-online' },
  {
    from: '/categoria-produto/salgados',
    to: '/categoria-produto/snacks-salgados',
  },
  {
    from: '/categoria-produto/suplementos-alimentares',
    to: '/categoria-produto/suplementos-elixires',
  },
  { from: '/elixires-bio', to: '/elixires' },
  {
    from: '/fiz-10-dias-de-dieta-lev-e-foi-isto-que-aconteceu',
    to: '/nutricao',
  },
  { from: '/beneficios-oleo-onagra', to: '/nutricao' },
  { from: '/rebentos-de-soja-beneficios-receita', to: '/receitas' },
  { from: '/receitas-lev/pure-de-brocolos', to: '/receitas' },
];

module.exports = function (req, res, next) {
  const url = req.url.split('?')[0];
  let urlParams = null;
  if (req.url.includes('?')) {
    urlParams = '?' + req.url.split('?')[1];
  }

  if (req.url?.startsWith('/recetas') && process.env.VUE_APP_LANG === 'pt') {
    res.writeHead(301, {
      Location: req.url.replace('/recetas', '/receitas'),
    });
    res.end();
  }

  const redirect = redirects.find((r) => r.from === url);
  if (redirect) {
    let newLocation;
    if (urlParams) {
      newLocation = redirect.to + urlParams;
    } else {
      newLocation = redirect.to;
    }
    res.writeHead(301, {
      Location: newLocation,
    });
    res.end();
  } else {
    next();
  }
};
