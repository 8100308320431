const middleware = {}

middleware['order'] = require('../middleware/order.js')
middleware['order'] = middleware['order'].default || middleware['order']

middleware['redirects'] = require('../middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['shop'] = require('../middleware/shop.js')
middleware['shop'] = middleware['shop'].default || middleware['shop']

export default middleware
