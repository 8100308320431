
export default {
  name: 'SortItem',

  props: {
    item: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      value: false,
    };
  },

  watch: {
    active(value) {
      this.value = value;
    },
  },

  mounted() {
    this.value = this.active;
  },

  methods: {
    click() {
      this.$emit('set', this.item);
    },
  },
};
