import { render, staticRenderFns } from "./promoNew.vue?vue&type=template&id=9c13c50e"
import script from "./promoNew.vue?vue&type=script&lang=js"
export * from "./promoNew.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Img: require('/vercel/path0/components/ui/img.vue').default,PrimaryButton: require('/vercel/path0/components/ui/primaryButton.vue').default,Link: require('/vercel/path0/components/ui/link.vue').default})
