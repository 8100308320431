
import { mapState, mapMutations } from 'vuex';
import WpImage from '~/components/ui/img.vue';

export default {
  name: 'ChatButton',

  components: {
    WpImage,
  },

  computed: {
    ...mapState({
      cookies: (state) => state.cookies,
      whatsapp: (state) => state.options.whatsapp,
      chatFormOpened: (state) => state.chatFormOpened,
      chatFormSent: (state) => state.chatFormSent,
      cartOpened: (state) => state.cartOpened,
      chatImage: (state) => state.options.chat_image,
    }),
  },

  methods: {
    ...mapMutations(['toggleChatForm', 'closeChatForm']),
  },
};
