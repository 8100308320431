
export default {
  name: 'Link',

  props: {
    to: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: '_self',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isUrl() {
      return /^http(s?):\/\/.*$/.test(this.to);
    },

    internalLink() {
      if (this.isUrl) {
        const url = new URL(this.to);
        return url.origin === process.env.WORDPRESS_URL ||
          url.origin === process.env.URL
          ? url.pathname
          : false;
      }
      return this.to;
    },

    externalLink() {
      return this.isUrl && !this.internalLink ? this.to : false;
    },

    nuxtLink() {
      if (this.internalLink === '/') {
        return this.localePath({ path: '/' });
      } else if (this.internalLink) {
        const namedRoute = this.$router.options.routes
          .filter((r) => !r.name.startsWith('slug__'))
          .sort(
            (a, b) =>
              Number(b.path.includes(':slug')) -
              Number(a.path.includes(':slug'))
          )
          .find((r) => {
            const regString = r.path.replace(/:slug\??/, '(.+)');
            const regExp = new RegExp(regString);
            return this.internalLink.match(regExp);
          });
        if (namedRoute) {
          const regString = namedRoute.path.replace(/:slug\??/, '(.+)');
          const regExp = new RegExp(regString);
          const match = this.internalLink.match(regExp);
          return this.localePath({
            name: namedRoute.name.replace(/_{3}[a-z]{2}/, ''),
            params: { slug: match[1] ? match[1].replace(/\//, '') : null },
          });
        } else {
          return this.internalLink;
        }
      }
      return null;
    },
  },

  methods: {
    clicked() {
      this.$emit('clicked');
    },
  },
};
