import { render, staticRenderFns } from "./chatForm.vue?vue&type=template&id=9384c7b6"
import script from "./chatForm.vue?vue&type=script&lang=js"
export * from "./chatForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Img: require('/vercel/path0/components/ui/img.vue').default,FieldText: require('/vercel/path0/components/ui/fieldText.vue').default,Field: require('/vercel/path0/components/ui/field.vue').default,SingleSelect: require('/vercel/path0/components/ui/singleSelect.vue').default,PrimaryButton: require('/vercel/path0/components/ui/primaryButton.vue').default})
