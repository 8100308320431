
export default {
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    optionkey: {
      type: String,
      default: 'id',
    },
    name: {
      type: String,
      default: 'name',
    },
    placeholder: {
      type: String,
      default: 'Choose',
    },
    withIcon: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: false,
      modelValue: null,
    };
  },
  computed: {
    selectedName() {
      return this.value
        ? this.options.find((c) => c[this.optionkey] === this.value)[this.name]
        : this.placeholder;
    },
  },
  watch: {
    modelValue(value) {
      this.$emit('input', value);
    },
    value(value) {
      this.modelValue = value;
    },
  },
  mounted() {
    this.modelValue = this.value;
  },
  methods: {
    emitDesktop(id) {
      this.$emit('input', id);
      this.toggleDesktopSelect();
    },
    toggleDesktopSelect() {
      this.open = !this.open;
    },
  },
};
