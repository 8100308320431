
import { mapState, mapMutations } from 'vuex';
import PrimaryButton from '@/components/ui/primaryButton';

export default {
  name: 'PromoNew',

  components: {
    PrimaryButton,
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      addFloat: false,
      weightCounter: 0,
    };
  },

  computed: {
    ...mapState(['options', 'promoOpened']),

    promo() {
      return Object.keys(this.options)
        .filter((k) => k.startsWith('promo_'))
        .reduce((o, k) => {
          o[k] = this.options[k];
          return o;
        }, {});
    },

    circles() {
      const fallbackImages = [
        require('~/assets/img/circle-1.png'),
        require('~/assets/img/circle-2.png'),
        require('~/assets/img/circle-3.png'),
        require('~/assets/img/circle-4.png'),
        require('~/assets/img/circle-5.png'),
        require('~/assets/img/circle-6.png'),
        require('~/assets/img/circle-7.png'),
      ];

      return [
        {
          weightLost: 19,
          image:
            this.promo.promo_image_group?.circle_images?.[0]?.image?.url ||
            fallbackImages[0],
          counter: 0,
        },
        {
          weightLost: 23,
          image:
            this.promo.promo_image_group?.circle_images?.[1]?.image?.url ||
            fallbackImages[1],
          counter: 0,
        },
        {
          weightLost: 5,
          image:
            this.promo.promo_image_group?.circle_images?.[2]?.image?.url ||
            fallbackImages[2],
          counter: 0,
        },
        {
          weightLost: 10,
          image:
            this.promo.promo_image_group?.circle_images?.[3]?.image?.url ||
            fallbackImages[3],
          counter: 0,
        },
        {
          weightLost: 18,
          image:
            this.promo.promo_image_group?.circle_images?.[4]?.image?.url ||
            fallbackImages[4],
          counter: 0,
        },
        {
          weightLost: 12,
          image:
            this.promo.promo_image_group?.circle_images?.[5]?.image?.url ||
            fallbackImages[5],
          counter: 0,
        },
        {
          weightLost: 7,
          image:
            this.promo.promo_image_group?.circle_images?.[6]?.image?.url ||
            fallbackImages[6],
          counter: 0,
        },
      ];
    },
  },

  mounted() {
    if (this.isVisible) {
      setTimeout(() => {
        this.addFloat = true;
      }, 2000);

      setTimeout(() => {
        this.circles.forEach((circle, index) => {
          const intervalId = setInterval(() => {
            if (circle.counter < circle.weightLost) {
              this.$set(this.circles[index], 'counter', circle.counter + 1);
            } else {
              clearInterval(intervalId);
            }
          }, 100);
        });
      }, 1000);
    }
  },

  methods: {
    ...mapMutations(['closePromo']),

    setTargetCookie() {
      // session cookie
      document.cookie = 'lev_promo_session=shown; path=/;';

      this.closePromo();
      this.$emit('close');
    },
  },
};
