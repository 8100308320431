
import algoliasearch from 'algoliasearch/lite';
import {
  AisInstantSearch,
  AisSearchBox,
  AisHits,
  AisHighlight,
  AisStateResults,
} from 'vue-instantsearch';

export default {
  name: 'SearchComponent',
  components: {
    AisInstantSearch,
    AisSearchBox,
    AisHighlight,
    AisHits,
    AisStateResults,
  },
  props: {
    isAlwaysOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      index: process.env.ALGOLIA_INDEX,
      searchClient: algoliasearch(
        process.env.ALGOLIA_APP,
        process.env.ALGOLIA_KEY
      ),
    };
  },
  mounted() {
    if (this.isAlwaysOpen) {
      this.isOpen = false;
    }
  },
  methods: {
    closeSearch() {
      this.isOpen = false;
    },
    openSearch() {
      this.isOpen = true;
    },
    toggleAndSearch() {
      this.isOpen = !this.isOpen;
      this.setDelayedFocus();
    },
    setDelayedFocus() {
      if (this.isOpen) {
        setTimeout(() => {
          this.focusInput();
        }, 500);
      }
    },
    focusInput() {
      let search;
      if (!this.isAlwaysOpen) {
        search = document.querySelector('.nav__nav');
      } else {
        search = document.querySelector('.menu__search');
      }
      search.querySelector('.ais-SearchBox-input').focus();
    },
    handleInputClick() {
      if (this.isAlwaysOpen) {
        this.toggleAndSearch();
        if (!this.isOpen) {
          this.openSearch();
        }
      }
    },
  },
};
