
export default {
  name: 'WpImage',

  props: {
    wpSizes: {
      type: Object,
      default: () => {},
    },
    alt: {
      type: String,
      default: null,
    },
    allow: {
      type: String,
      default: null,
    },
    exclude: {
      type: String,
      default:
        'woocommerce_thumbnail,woocommerce_gallery_thumbnail,thumbnail,shop_thumbnail,shop_catalog',
    },
    src: {
      type: String,
      default: null,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
  },

  data() {
    return {
      version: 2,
    };
  },

  computed: {
    allowed() {
      return this.allow ? this.allow.split(',').map((s) => s.trim()) : [];
    },

    excluded() {
      return this.exclude ? this.exclude.split(',').map((s) => s.trim()) : [];
    },

    default() {
      return this.sizes.length ? this.sizes[this.sizes.length - 1] : null;
    },

    original() {
      return this.sizes.length ? this.sizes[0] : null;
    },

    computedSrc() {
      if (this.src) return `${this.src}?v=${this.version}`;

      return this.default
        ? `${this.default.url}?v=${this.version}`
        : this.sizes.length
        ? `${this.sizes[0].url}?v=${this.version}`
        : null;
    },

    computedSizes() {
      return this.original
        ? `(max-width: ${this.original.width}px) 100vw, ${this.original.width}px`
        : null;
    },

    srcset() {
      return this.sizes.map((s) => {
        return { url: `${s.url}?v=${this.version}`, width: `${s.width}px` };
      });
    },

    allSizes() {
      if (!this.wpSizes) return [];

      const data = [];
      Object.keys(this.wpSizes).forEach((key) => {
        const chunks = key.split('-');
        let name, attribute;
        if (!['width', 'height'].includes(chunks[chunks.length - 1])) {
          name = chunks.join('-');
          attribute = null;
        } else {
          attribute = chunks[chunks.length - 1];
          chunks.splice(-1);
          name = chunks.join('-');
        }
        if (attribute === null) {
          data.push({
            url: this.wpSizes[key],
            name,
          });
        } else {
          const index = data.findIndex((d) => d.name === name);
          if (data[index]) {
            data[index][attribute] = this.wpSizes[key];
          }
        }
      });

      return data.sort((a, b) => a.width - b.width);
    },

    sizes() {
      const allowed = this.allowed.length
        ? [...this.allSizes].filter((s) => this.allowed.includes(s.name))
        : [...this.allSizes];

      return this.excluded.length
        ? [...allowed].filter((s) => !this.excluded.includes(s.name))
        : [...allowed];
    },
  },
};
