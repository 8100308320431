
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'UserLinks',

  data() {
    return {
      dropdownOpened: false,
      showConfirmLogout: false,
    };
  },

  computed: {
    ...mapState(['user']),
  },

  mounted() {
    const routes = [
      '/my-products',
      '/meus-produtos',
      '/mis-productos',
      '/mes-produits',
      '/my-favorites',
      '/meus-favoritos',
      '/mis-favoritos',
      '/mes-favoris',
      '/my-watchlist',
      '/minha-lista-de-observacao',
      '/mi-lista-de-seguimiento',
      '/ma-liste-de-surveillance',
      '/my-page',
      '/minha-pagina',
      '/mi-pagina',
      '/ma-page',
    ];
    if (routes.includes(this.$route.path)) {
      this.dropdownOpened = true;
    }
  },

  methods: {
    ...mapMutations([
      'setUser',
      'setToken',
      'setPatientId',
      'setDeliveryAddress',
      'setShippingLeft',
    ]),

    showConfirm() {
      this.showConfirmLogout = true;
    },

    hideConfirm() {
      this.showConfirmLogout = false;
    },

    logout() {
      this.setUser(null);
      this.setPatientId(null);
      this.setToken(null);
      this.setDeliveryAddress([]);
      this.setShippingLeft(0);
      this.$router.push(this.localePath({ name: 'shop' }));
    },
  },
};
