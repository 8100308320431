export default (context, inject) => {
  const getData = ({ name, description, image, type, data, url }) => {
    const basicData = {
      '@context': 'http://schema.org',
      '@type': type,
      name,
      image,
      description,
      url,
    };
    return {
      script: [
        {
          type: 'application/ld+json',
          json: { ...basicData, ...data },
        },
      ],
    };
  };

  inject('gdata', {
    product: ({ brand, price, inStock, product, currency, url }) => {
      const data = {
        brand: {
          '@type': 'Brand',
          name: brand,
        },
        productId: product.sku,
        offers: {
          '@type': 'Offer',
          price,
          itemCondition: 'https://schema.org/NewCondition',
          availability: inStock
            ? 'https://schema.org/InStock'
            : 'https://schema.org/OutOfStock',
          priceCurrency: currency,
          url,
          sku: product.sku,
        },
      };

      return getData({
        type: 'Product',
        name: product.name,
        description: product.yoast_head_json.og_description,
        image: product.yoast_head_json.og_image
          ? product.yoast_head_json.og_image[0].url
          : product.images.length
          ? product.images[0].src
          : null,
        data,
      });
    },
  });
};
