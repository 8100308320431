
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    stockAmount: {
      type: Number,
      default: 0,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      quantity: 0,
    };
  },

  watch: {
    quantity(value) {
      this.$emit('input', value);
    },
    value(value) {
      this.quantity = value;
    },
  },

  mounted() {
    this.quantity = this.value;
  },

  methods: {
    isNumber(evt) {
      evt = evt || window.event;
      const charCode = evt.which || evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
