
import Error404 from '@/components/errors/404.vue';
import Error500 from '@/components/errors/500.vue';

export default {
  name: 'NuxtError',

  layout: 'default',

  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    errorPage() {
      if (this.error.statusCode === 404) {
        return Error404;
      }
      return Error500;
    },
  },
};
