export default ({ store, route }, inject) => {
  inject('seo', {
    yoast: (json) => {
      const tags = Object.keys(json).map((e) => ({ key: e, value: json[e] }));

      const meta = tags
        .filter(
          (t) =>
            ![
              'og_image',
              'og_locale',
              'robots',
              'schema',
              'title',
              'og_url',
              'og_type',
            ].includes(t.key)
        )
        .map((t) => ({
          hid: t.key,
          name: t.key.replace('og_', 'og:'),
          content: t.value,
        }));

      const image = tags.find((t) => t.key === 'og_image');
      if (image && image.value && typeof image.value === 'string') {
        meta.push({
          content: image,
          hid: 'og_image',
          name: 'og:image',
        });
      } else if (image && image.value.length) {
        meta.push({
          content: image.value[0].url,
          hid: 'og_image',
          name: 'og:image',
        });
      }

      const url = tags.find((t) => t.key === 'og_url');
      if (url) {
        meta.push({
          content: url.value.replace(
            process.env.WORDPRESS_URL,
            process.env.URL
          ),
          name: 'og:url',
          hid: 'og_url',
        });
      }

      return {
        title: tags
          .find((t) => t.key === 'title')
          .value.replace(/&amp;/gi, '&'),
        meta,
      };
    },

    page: (page, path) => {
      const data =
        store.state.options && store.state.options[`field_${page}_start`]
          ? store.state.options[`field_${page}_start`]
          : null;

      const meta = [
        {
          content: data.title,
          hid: 'og_title',
          name: 'og:title',
        },
        {
          content: data.description,
          hid: 'description',
          name: 'description',
        },
        {
          content: data.description,
          hid: 'og_description',
          name: 'og:description',
        },
        {
          content: `${process.env.URL}${path}`,
          hid: 'og_url',
          name: 'og:url',
        },
      ];

      if (data.image) {
        meta.push({
          content: data.image.url,
          hid: 'og_image',
          name: 'og:image',
        });
      }

      return {
        title: data.title,
        meta,
      };
    },

    product: ({ price, brand, stock, sku }) => {
      return [
        { hid: 'og_type', name: 'og:type', content: 'product' },
        {
          name: 'og:price:amount',
          content: price,
        },
        {
          name: 'og:price:currency',
          content: process.env.VUE_APP_CURRENCY || 'EUR',
        },
        {
          name: 'product:brand',
          content: brand,
        },
        {
          name: 'product:currency',
          content: process.env.VUE_APP_CURRENCY || 'EUR',
        },
        {
          name: 'product:locale',
          content: process.env.VUE_APP_LOCALE || 'pt_PT',
        },
        {
          name: 'product:availability',
          content: stock,
        },
        { name: 'product:condition', content: 'new' },
        {
          name: 'product:price:amount',
          content: price,
        },
        {
          name: 'product:retailer_item_id',
          content: sku,
        },
      ];
    },

    path: () => {
      return `${process.env.URL}${route.fullPath}`;
    },

    currency: () => {
      return process.env.VUE_APP_CURRENCY || 'EUR';
    },
  });
};
