
import { mapState, mapMutations } from 'vuex';
import layoutMixin from '@/mixins/layout.js';
import NavMain from '@/components/navMain.vue';
import Footer from '@/components/footer.vue';
import Sidebar from '@/components/shop/sidebar.vue';

export default {
  name: 'ProductLayout',

  components: {
    NavMain,
    Footer,
    Sidebar,
  },

  mixins: [layoutMixin],

  computed: {
    ...mapState(['loginOpened']),
  },

  methods: {
    ...mapMutations(['closeLoginModal']),
  },
};
