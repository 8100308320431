
import PrimaryButton from '@/components/ui/primaryButton.vue';

export default {
  name: 'Error500',

  components: {
    PrimaryButton,
  },

  props: {
    error: {
      type: Object,
      required: true,
    },
  },
};
