
import { mapMutations, mapState } from 'vuex';
import SingleSelect from '@/components/ui/singleSelect';
import PrimaryButton from '@/components/ui/primaryButton';

export default {
  name: 'ContactForm',

  components: {
    SingleSelect,
    PrimaryButton,
  },

  props: {
    defaultClinic: {
      type: Object,
      default: () => {},
    },
    modal: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      sent: false,
      loading: false,
      redirecting: false,
      message: null,
      name: null,
      email: null,
      phone: null,
      clinic: null,
      portugalDefaultOptions: [
        { id: 'info', name: this.$t('contactForm.moreInfo') },
        { id: 'online', name: this.$t('contactForm.online') },
      ],
      spanishDefaultOptions: [
        { id: 'online', name: this.$t('contactForm.online') },
      ],
    };
  },

  computed: {
    ...mapState({
      clinics: (state) => state.clinics,
      closestClinicId: (state) => state.closestClinicId,
      chatFormOpened: (state) => state.chatFormOpened,
      patientId: (state) => state.patientId,
      user: (state) => state.user,
      utm: (state) => state.utm,
      thankyouPage: (state) => state.options.thankyou_page,
    }),

    submitButtonMessage() {
      return this.sent
        ? this.$t('forms.success')
        : this.$t('contactForm.submit');
    },

    defaultOptions() {
      return this.isSpanish
        ? this.spanishDefaultOptions
        : this.portugalDefaultOptions;
    },

    clinicOptions() {
      return [
        ...this.clinics.map((c) => ({
          id: c.fields.sage_id,
          name: c.title.rendered
            .replace(/&amp;/gi, '&')
            .replace(/&#8211;/gi, '-'),
        })),
        ...this.defaultOptions,
      ];
    },

    inputs() {
      return `${this.name}${this.email}${this.phone}${this.clinic}`;
    },
  },

  watch: {
    inputs() {
      this.resetMessage();
    },
  },

  async mounted() {
    const { data } = await this.$wp.clinics();
    if (data.length) {
      this.setClinics(data);
    }
    if (this.defaultClinic) {
      this.clinic = this.defaultClinic.fields.sage_id;
    } else if (this.closestClinicId) {
      this.clinic = this.clinics.find(
        (c) => c.id === this.closestClinicId
      ).fields.sage_id;
    }
    if (this.user) {
      this.email = this.user.email;
      this.name = this.user.name;
      this.phone = this.user.phone;
    }
  },

  beforeMount() {
    if (this.contactFormOpened) {
      document.body.classList.add('no-scroll');
    }
  },

  beforeDestroy() {
    document.body.classList.remove('no-scroll');
  },

  methods: {
    ...mapMutations(['closeContactForm', 'toggleContactForm', 'setClinics']),

    resetMessage() {
      this.message = null;
    },

    getUriFromUrl(url) {
      try {
        const parsedUrl = new URL(url);
        return parsedUrl.pathname;
      } catch (error) {
        console.error('Invalid URL:', error);
        return url;
      }
    },

    async submit() {
      if (!this.sent) {
        this.loading = true;
        if (!this.clinic) {
          this.loading = false;
          this.message = this.$t('forms.choose');
        } else if (this.name && this.phone && this.email) {
          try {
            await this.$bolev.createLead({
              name: this.name,
              phone: this.phone,
              email: this.email,
              source: 'website',
              clinicId: this.clinic,
              patientId: this.patientId,
              formId: `${this.type} (${this.$route.path})`,
              utm: this.utm,
            });

            this.loading = false;
            this.sent = true;
            if (this.thankyouPage) {
              this.redirecting = true;
              const uri = this.getUriFromUrl(this.thankyouPage);
              this.$router.push({
                path: uri,
                query: {
                  name: this.name,
                  email: this.email,
                  phone: this.phone,
                  clinic: this.clinic,
                },
              });
              this.$router.afterEach(() => {
                this.closeContactForm();
                this.redirecting = false;
              });
            } else {
              this.message = this.$t('contactForm.thankYou', {
                name: this.name,
              });
            }
          } catch (_) {
            this.loading = false;
            this.message = this.$t('forms.invalid');
          }
        } else {
          this.loading = false;
          this.message = this.$t('forms.empty');
        }
      }
    },
  },
};
