export default function ({ store, route }) {
  const paths = [
    /^checkout(___[a-z]{2})?$/,
    /^product-category-slug(___[a-z]{2})?$/,
    /^shop(___[a-z]{2})?$/,
    /^my-products(___[a-z]{2})?$/,
    /^my-favorites(___[a-z]{2})?$/,
    /^my-watchlist(___[a-z]{2})?$/,
    /^product-tag-slug(___[a-z]{2})?$/,
    /^product-slug(___[a-z]{2})?$/,
    /^results(___[a-z]{2})?$/,
    /^order-confirm(___[a-z]{2})?$/,
  ];

  if (paths.some((p) => p.test(route.name)) && !store.state.inShop) {
    store.commit('setInShop');
  } else if (!paths.some((p) => p.test(route.name)) && store.state.inShop) {
    store.commit('setNotInShop');
  }
}
