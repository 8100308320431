
import PrimaryButton from '@/components/ui/primaryButton.vue';

export default {
  name: 'Error404',

  components: {
    PrimaryButton,
  },

  props: {
    error: {
      type: Object,
      default: null,
    },
  },
};
