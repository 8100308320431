
import Spinner from '@/components/ui/spinner';

// ICON AND LINK SAVING AND DISABLED ARE OPTIONAL PROPS
// Pass name of img for icon, place in assets

export default {
  name: 'PrimaryButton',

  components: {
    Spinner,
  },

  props: {
    title: {
      type: String,
      default: 'Accept',
    },
    link: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    finish: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '100%',
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Boolean,
      default: false,
    },
    chat: {
      type: Boolean,
      default: false,
    },
    green: {
      type: Boolean,
      default: false,
    },
  },
};
