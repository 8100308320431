
import { mapState, mapMutations, mapActions } from 'vuex';
import MenuItem from '@/components/menuItem.vue';
import Cart from '@/components/shop/cart.vue';
import Menu from '@/components/menu.vue';
import TopBanner from '@/components/topBanner.vue';
import SearchAlgolia from '@/components/searchAlgolia.vue';
import Breadcrumbs from '@/components/shop/breadcrumbs.vue';
import Link from '@/components/ui/link.vue';

export default {
  name: 'NavMain',

  components: {
    MenuItem,
    Cart,
    Menu,
    TopBanner,
    SearchAlgolia,
    Breadcrumbs,
    Link,
  },

  props: {
    shop: {
      type: Boolean,
      default: false,
    },
  },

  async fetch() {
    const data = await this.$wp.menu();
    this.setMenu(data.data);
  },

  data() {
    return {
      animate: false,
      firstLoad: true,
      showNavbar: true,
      lastScrollPosition: 0,
    };
  },

  computed: {
    ...mapState([
      'menu',
      'contactFormOpened',
      'options',
      'sortCriteria',
      'cookies',
      'user',
      'myFavorites',
    ]),

    banner() {
      return Object.keys(this.options)
        .filter((k) => k.startsWith('banner_'))
        .reduce((o, k) => {
          o[k] = this.options[k];
          return o;
        }, {});
    },

    logoHeight() {
      return this.showNavbar ? 59 : 40;
    },

    logoWidth() {
      return this.showNavbar ? 79 : 53;
    },

    isProductPage() {
      return this.$route.name.startsWith('product-slug');
    },

    bannerVisible() {
      return this.banner && this.banner.banner_show;
    },
  },

  watch: {
    myFavorites(newVal) {
      if (this.firstLoad) {
        this.firstLoad = false;
      } else if (newVal.length) {
        this.animate = true;
        setTimeout(() => {
          this.animate = false;
        }, 400);
      }
    },
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },

  methods: {
    ...mapMutations([
      'setMenu',
      'toggleContactForm',
      'closeContactForm',
      'toggleFilter',
      'toggleMenu',
      'openLoginModal',
    ]),
    ...mapActions(['gtEvent']),

    whatsAppClick() {
      // this.gtEvent({
      //   action: 'event',
      //   label: 'tracking',
      //   value: 'something',
      // });
    },

    handleFavoriteClick() {
      if (!this.user) {
        this.openLoginModal();
      } else {
        this.$router.push(this.localePath({ name: 'my-favorites' }));
      }
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollPosition < 0) {
        return;
      }

      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 120) {
        return;
      }

      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
  },
};
