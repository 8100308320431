
import { mapMutations, mapActions, mapState } from 'vuex';
import WpImage from '~/components/ui/img.vue';
import Counter from '@/components/ui/counter.vue';
import { calculateStockAmount } from '@/utils/stock';

export default {
  name: 'CartItem',

  components: {
    WpImage,
    Counter,
  },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
    checkout: {
      type: Boolean,
      default: false,
    },
    paid: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    deleted: {
      type: Boolean,
      default: false,
    },
    updated: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      stockAmount: 0,
      stockInterval: null,
    };
  },

  computed: {
    ...mapState(['patientId']),

    name() {
      return this.$store.getters.getProductName(this.item.sku);
    },
    slug() {
      return this.$store.getters.getProductSlug(this.item.sku);
    },
    price() {
      return this.$store.getters.getActualPrice(this.item.sku);
    },
    imageSizes() {
      const images = this.$store.getters.getProductImages(this.item.sku);
      return images.length ? images[0].sizes : {};
    },
    bg() {
      return this.$store.getters.getProductBg(this.item.sku);
    },
  },

  mounted() {
    this.getStockAmount(this.item.sku);
    this.stockInterval = setInterval(() => {
      if (this.item) {
        this.getStockAmount(this.item.sku);
      }
    }, 4000);
  },

  beforeDestroy() {
    if (this.stockInterval) {
      clearInterval(this.stockInterval);
    }
  },

  methods: {
    ...mapMutations([
      'editCartItem',
      'removeCartItem',
      'setCalculatedShipping',
      'setShippingLeft',
      'addRemovedItem',
      'addUpdatedItem',
    ]),
    ...mapActions(['updateOrder']),

    async getStockAmount(sku) {
      try {
        const { data } = await this.$bolev.getProductStock({
          sku,
          patientId: this.patientId,
        });
        this.stockAmount = calculateStockAmount(data);
        if (this.stockAmount < this.item.quantity) {
          if (this.stockAmount === 0) {
            this.removeCartItem(this.item.sku);
            this.addRemovedItem(this.item);
          } else {
            this.addUpdatedItem(this.item);
            this.editCartItem({
              sku: this.item.sku,
              quantity: this.stockAmount,
            });
          }
          if (this.checkout) {
            setTimeout(() => {
              this.updateOrder();
            }, 500);
            if (!this.order || !this.order.shipping) {
              this.$emit('updateShipping');
            }
          } else {
            this.$emit('updateCart');
          }
        }
      } catch (_) {
        this.errorMessage = 'Something went wrong';
      }
    },

    addOne() {
      if (this.stockAmount > this.item.quantity) {
        this.editCartItem({
          sku: this.item.sku,
          quantity: this.item.quantity + 1,
        });
        if (this.checkout) {
          this.updateOrder();
          if (!this.order || !this.order.shipping) {
            this.$emit('updateShipping');
          }
        } else {
          this.$emit('updateCart');
        }
      }
    },

    removeOne() {
      if (this.item.quantity > 1) {
        this.editCartItem({
          sku: this.item.sku,
          quantity: this.item.quantity - 1,
        });
      } else {
        this.removeCartItem(this.item.sku);
      }
      if (this.checkout) {
        this.updateOrder();
        if (!this.order || !this.order.shipping) {
          this.$emit('updateShipping');
        }
      } else {
        this.$emit('updateCart');
      }
    },
  },
};
