
import { mapMutations, mapState } from 'vuex';
import Link from '@/components/ui/link.vue';

export default {
  name: 'Footer',

  components: {
    Link,
  },

  computed: {
    ...mapState(['options', 'menu']),
  },

  methods: {
    ...mapMutations(['openContactForm']),
  },
};
