
import { mapState, mapMutations } from 'vuex';
import Link from '@/components/ui/link.vue';

export default {
  name: 'MenuItem',

  components: {
    Link,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    image: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  computed: {
    ...mapState(['isPost']),

    slug() {
      return this.item.url.replace(/\/$/, '').split('/')[
        this.item.url.replace(/\/$/, '').split('/').length - 1
      ];
    },

    isBlog() {
      return (
        (this.slug === 'blog' &&
          ['blog', 'category-slug'].includes(this.$route.name)) ||
        (this.slug === 'blog' &&
          this.isPost &&
          'slug'.includes(this.$route.name))
      );
    },

    isShop() {
      return (
        `/${this.slug}` === this.localePath({ name: 'shop' }) &&
        this.$route.path !== '/' &&
        [
          this.localePath({ name: 'shop' }),
          this.localePath({ name: 'product-slug' }),
          this.localePath({ name: 'my-products' }),
          this.localePath({ name: 'product-category-slug' }),
          this.localePath({ name: 'product-tag-slug' }),
          this.localePath({ name: 'checkout' }),
          this.localePath({ name: 'review-order-hash' }),
          this.localePath({ name: 'order-confirm-hash' }),
        ].includes(this.$route.path)
      );
    },
  },

  methods: {
    ...mapMutations(['closeMenu']),
  },
};
