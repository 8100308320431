
import { mapGetters, mapMutations, mapState } from 'vuex';
import Row from '@/components/shop/row.vue';

export default {
  name: 'OrderSummary',

  components: {
    Row,
  },

  props: {
    sub: {
      type: Boolean,
      default: true,
    },
    cart: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      zip: null,
    };
  },

  computed: {
    ...mapGetters(['subTotal']),
    ...mapState(['order', 'calculatedShipping']),

    total() {
      return this.calculatedShipping
        ? this.subTotal + this.calculatedShipping.shipping
        : this.subTotal;
    },
  },
  methods: {
    ...mapMutations(['setCalculatedZip']),

    handleZip() {
      this.setCalculatedZip(this.zip);
      this.$emit('updateShipping', true);
    },
  },
};
