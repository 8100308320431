
import { mapState } from 'vuex';

export default {
  name: 'Breadcrumbs',

  data() {
    return {
      base: [
        {
          name: this.$t('shop.shop'),
          url: this.localePath({ name: 'shop' }),
          id: 'base',
        },
      ],
    };
  },

  computed: {
    ...mapState(['categories', 'tags', 'products']),

    parentCategory() {
      return this.category && this.category.parent
        ? this.categories.find((c) => c.id === this.category.parent)
        : null;
    },

    slug() {
      return this.$route.params.slug;
    },

    category() {
      if (this.isCategoryPage) {
        return this.categories.find((t) => t.slug === this.slug);
      } else if (
        this.isProductPage &&
        this.product &&
        this.product.categories.length
      ) {
        const subCategory = this.categories.find(
          (c) =>
            this.product.categories.map((k) => k.id).includes(c.id) &&
            c.parent > 0
        );
        if (subCategory) {
          return subCategory;
        }
        return (
          this.categories.find((c) =>
            this.product.categories.map((k) => k.id).includes(c.id)
          ) || null
        );
      }
      return null;
    },

    tag() {
      return this.isTagPage
        ? this.tags.find((t) => t.slug === this.slug)
        : null;
    },

    product() {
      return this.isProductPage
        ? this.products.find((p) => p.slug === this.slug)
        : null;
    },

    crumbs() {
      const c = [...this.base];
      if (this.tag) {
        c.push({
          name: this.tag.name,
          id: 'tag',
        });
      }
      if (this.parentCategory) {
        c.push({
          name: this.parentCategory.name,
          url: this.localePath({
            name: 'product-category-slug',
            params: { slug: this.parentCategory.slug },
          }),
          id: 'parent-cat',
        });
      }
      if (this.category) {
        c.push({
          name: this.category.name,
          url: this.localePath({
            name: 'product-category-slug',
            params: { slug: this.category.slug },
          }),
          id: 'cat',
        });
      }
      if (this.product) {
        c.push({
          name: this.product.name,
          url: null,
          id: 'product',
        });
      }
      return c;
    },

    isProductPage() {
      return this.$route.name.startsWith('product-slug');
    },

    isCategoryPage() {
      return this.$route.name.startsWith('product-category-slug');
    },

    isTagPage() {
      return this.$route.name.startsWith('product-tag-slug');
    },

    links() {
      return [...this.crumbs].slice(0, -1);
    },

    span() {
      return this.crumbs[this.crumbs.length - 1];
    },
  },

  methods: {
    backToCategory() {
      const cat = this.links[this.links.length - 1];
      this.$router.push({ path: cat.url });
    },
  },
};
