
import { mapState, mapMutations } from 'vuex';
import Login from '@/components/login.vue';
import Link from '@/components/ui/link.vue';
import SearchAlgolia from '@/components/searchAlgolia.vue';

export default {
  name: 'Menu',

  components: {
    Login,
    Link,
    SearchAlgolia,
  },

  computed: {
    ...mapState(['menu', 'menuOpened', 'options']),

    menuImages() {
      return this.options.menu_images;
    },
  },

  watch: {
    $route() {
      this.closeMenu();
    },
  },

  mounted() {
    if (this.menuOpened) {
      this.closeMenu();
    }
  },

  methods: {
    ...mapMutations(['closeMenu']),
  },
};
