import Vue from 'vue';

Vue.directive('clickOutside2', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (
        !(el === event.target || el.contains(event.target)) &&
        vnode.context[binding.arg]
      ) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});
