import Vue from 'vue';

const animateOnScrollObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      const ratio = entry.intersectionRatio;
      if (
        entry.isIntersecting &&
        entry.boundingClientRect.height > window.innerHeight
      ) {
        setTimeout(() => {
          entry.target.play();
        }, 500);
      }
      if (ratio > 0.1) {
        setTimeout(() => {
          entry.target.play();
        }, 500);
      } else if (
        ratio < 0.1 ||
        entry.boundingClientRect.height < window.innerHeight
      ) {
        entry.target.pause();
      }
    });
  },
  { threshold: [0.1] }
);

Vue.directive('intersect', {
  bind: (el, binding) => {
    if (binding.value) {
      setTimeout(() => {
        animateOnScrollObserver.observe(el);
      }, 100);
    }
  },
});
